import React from 'react'
import propTypes from 'prop-types'
import { graphql } from 'gatsby'
import Hero from '../components/Hero'
import Layout from '../components/layout'
import ContentWrapper from '../components/ContentWrapper'
import ExampleGrid from '../components/ExampleGrid'
import ExampleCard from '../components/ExampleCard'
import ShareBanner from '../components/ShareBanner'

const ExamplesPage = ({ data: { exampleInfo, examples, shareBanner } }) => (
  <Layout title="Browse examples">
    <Hero
      heading="Browse examples"
      heroImage={exampleInfo.icon && exampleInfo.icon}
      description={exampleInfo.categoryPageDefinition}
    />
    <ContentWrapper>
      <ExampleGrid>
        {examples &&
          examples.nodes.map((example) => (
            <ExampleCard
              key={example.slug}
              slug={example.slug}
              name={example.name}
              thumbnail={example.thumbnail}
              organisation={example.contributingOrganisation.name}
            />
          ))}
      </ExampleGrid>
    </ContentWrapper>
    {shareBanner && (
      <ShareBanner
        heading={shareBanner.heading}
        image={shareBanner.icon}
        position={shareBanner.iconPosition}
        bodyText={shareBanner.bodyText}
      />
    )}
  </Layout>
)

ExamplesPage.propTypes = {
  data: propTypes.objectOf(propTypes.any).isRequired,
}

export const query = graphql`
  {
    exampleInfo: contentfulContentTypeInfo(contentType: { eq: "example" }) {
      categoryPageDefinition {
        json
      }
      icon {
        file {
          url
        }
      }
      contentType
    }
    examples: allContentfulExample(sort: { fields: createdAt, order: DESC }) {
      nodes {
        name
        contributingOrganisation {
          name
        }
        slug
        thumbnail {
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid
          }
          description
        }
      }
    }
    shareBanner: contentfulShareBanner(type: { eq: "example" }) {
      heading
      bodyText
      iconPosition
      icon {
        title
        file {
          url
        }
      }
    }
  }
`

export default ExamplesPage
